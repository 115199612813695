@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Giants";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/Giants-Regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BM-HANNA";
  font-display: fallback;
  src: url("./fonts/BM-HANNA.ttf") format("truetype"), url("./fonts/BM-HANNA.woff")
      format("woff"), url("./fonts/BM-HANNA.eot?iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}
